import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import './i18n/i18n';
import './index.scss';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { KioskProvider } from './context/KioskContext';
import { msalConfig } from './authConfig';
import React from 'react';
// import { ThemeProvider, CssBaseline } from "@mui/material";
// import { appTheme } from "./themes/theme";


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  // {/* <ThemeProvider theme={appTheme}> */}
  // {/* <CssBaseline enableColorScheme/> */}
      <KioskProvider>
        <MsalProvider instance={msalInstance}>
          <Suspense fallback={<div>loading...</div>}>
            <App/>
          </Suspense>
        </MsalProvider>
      </KioskProvider>
  // {/* </ThemeProvider> */}
  // </React.StrictMode>
);

reportWebVitals();
