import { FC, ReactNode, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import SideMenu from '../../components/SideMenu';
import SalePathNodeCollection from '../../components/SalePathNodeCollection';
import SalePathProduct from '../../components/SalePathProduct';
import ConfirmBasketDialog from '../../components/BasketDialog';
import styles from './styles.module.scss';
import { ConfirmStep, OrderPayment, SelectOrderNumber, SelectPlaceStep, SelectDeliveryMethod, SelectTableServicing } from "../../components/OrderSteps";
import { useTranslation } from "react-i18next";
import { OrderStepsEnum } from '../../../context/KioskContext/types';
import ConceptHeader from '../ConceptHeader';
import SalePath from '../../../models/SalePath';

const KioskOpen: FC = () => {
  const {state} = useContext(KioskContext);

  const {t} = useTranslation();

  const rootItem:SalePath|null = state.salePath ? state.salePath.getRoot() : null;

  const title = () : string => {
    if (state.isBrowsing) {

      if (state.orderSteps) {
      
        switch (state.orderSteps) {
          case OrderStepsEnum.STEP_ONE: {
            return 'where-do-eat';
          }
          case OrderStepsEnum.STEP_TWO: {
            return 'delivery-method';
          }
          case OrderStepsEnum.STEP_THREE: {
            return 'table-servicing';
          }
          case OrderStepsEnum.STEP_FOUR: {
            return 'confirm-order';
          }
          case OrderStepsEnum.STEP_FIVE: {
            return '';
          }
          case OrderStepsEnum.STEP_SIX: {
            return 'thank-for-order';
          }
        }
      } else {
        return '';
      }
    }
    return '';
  }

  const renderPageContent = (): ReactNode => {
    if (state.isBrowsing) {

      if (state.orderSteps) {
      
        switch (state.orderSteps) {
          case OrderStepsEnum.STEP_ONE: {
            return <SelectPlaceStep />
          }
          case OrderStepsEnum.STEP_TWO: {
            return <SelectDeliveryMethod />
          }
          case OrderStepsEnum.STEP_THREE: {
            return <SelectTableServicing />
          }
          case OrderStepsEnum.STEP_FOUR: {
            return <ConfirmStep/>
          }
          case OrderStepsEnum.STEP_FIVE: {
            return <OrderPayment/>
          }
          case OrderStepsEnum.STEP_SIX: {
            return <SelectOrderNumber/>
          }
        }
      } else {
        if (state.salePath?.nodeType === 'Product' && state.editData) {
          return <SalePathProduct node={state.editData.rootItem}/>
        } else {
          return <SalePathNodeCollection/>
        }
      }
    }
  }

  return (
    <div className={styles.kiosk}>
      <div className={styles.header}>
        {rootItem && <ConceptHeader node={rootItem} />}
        <div className={state.salePath ? styles.salePathSubTitle : styles.salePathProductSubTitle}>
          <h2>
            {state.orderSteps <= 0 && !state.salePath?.name ?
              state.cart==null&&t('start')
              :
              state.orderSteps > 0 ? t(`${title()}`) : state.salePath?.name
            }
          </h2>
        </div>
      </div>
      <div className={styles.salePath}>
      {((state.salePath || state.cart) && state.orderSteps <= 4) &&
          <div className={styles.sideBar}>
            <SideMenu/>
          </div>
        }
        <div className={styles.content}>
          <div className={state.salePath ? '' : styles.notSalePath}>
            {renderPageContent()}
          </div>
          </div>
        <div className={styles.border}></div>
      </div>
      <ConfirmBasketDialog open={state.isConfirmingBasket}/>
    </div>
  );
};

export default KioskOpen;
