import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from "../../../../context/KioskContext";
import { KioskActionsEnum } from "../../../../context/KioskContext/types";
import NumericEntry from '../../NumericEntry';
import { DeliveryMethodEnum, setDeliveryMethod, setServingId } from '../../../../helpers/basketApi';

export const SelectTableServicing = () => {
  const {state, dispatch} = useContext(KioskContext)

  const [ numericValue, setNumericValue ] = useState<number|undefined>(undefined);

  const [isTakeAway] = useState<boolean>((state.cart?.deliveryMethod||DeliveryMethodEnum.FrontCounter)===DeliveryMethodEnum.Takeaway);

  const proceedWithServicingId = async (servicingId:string) => {
    if (state.cart){
      var cart = await setDeliveryMethod(state.cart.id, isTakeAway ? DeliveryMethodEnum.TakeawayTableServing : DeliveryMethodEnum.TableServing);
      cart = await setServingId(state.cart.id, servicingId);
      dispatch({type: KioskActionsEnum.UPDATE_CART, cart});
      dispatch({type: KioskActionsEnum.SELECT_NEXT_STEP})
    }
  }

  const selectAtTable = (): void => {
    if (numericValue&&numericValue>0){
      proceedWithServicingId(numericValue.toString());
    }
  };
  
  return (
    <div className={styles.selectTableServicing}>
      <div 
        className={`container ${styles.placeCard}`} 
        onClick={() => selectAtTable()}
      >
        <span>{numericValue}</span>
      </div>
      <div className={styles.keypad} >
        <NumericEntry value={numericValue} onValueChange={(value) => setNumericValue(value)}/>
      </div>
    </div>
  );
};